<template>
  <div id="index">
    <!--  内容区  -->
    <div class="main-content">
      <router-view/>
    </div>
    <!--  底部导航  -->
    <div class="index-tab-bar">
      <router-link v-for="(item, index) in routers" :key="index" class="nav-item" :to="item.path">
        <img class="nav-icon" :src="$route.name === item.routerName ? item.icActive : item.icNormal"
             alt/>
        <span :class="($route.name === item.routerName) ? 'nav-title nav-active':'nav-title'">{{item.title}}</span>
      </router-link>
    </div>
  </div>
</template>

<script>
  export default {
    components: {},
    props: [],
    data() {
      return {
        type: 1,
        routers: [
          {
            title: '订单',
            routerName: 'orders',
            path: '/orders',
            icNormal: require('@images/home.png'),
            icActive: require('@images/home-active.png')
          },
          {
            title: '数据',
            routerName: 'dataCenter',
            path: '/dataCenter',
            icNormal: require('@images/data.png'),
            icActive: require('@images/data-active.png')
          },
          {
            title: '我的',
            routerName: 'mine',
            path: '/mine',
            icNormal: require('@images/mine.png'),
            icActive: require('@images/mine-active.png')
          }
        ],
        index: require("@images/home-active.png"),
        center: require("@images/data.png")
      };
    }
  };
</script>
<style lang="scss">
  @import '../common/css/theme.scss';

  #index {
    height: 100vh;

    .main-content {
      height: calc(100vh - 54px);
    }

    .index-tab-bar {
      position: fixed;
      bottom: 0;
      left: 0;
      height: 54px;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
      background-color: white;
      box-shadow: 0px -2px 4px rgba(192, 196, 204, 0.16);

      .nav-item {
        display: flex;
        flex-direction: column;
        align-items: center;

        .nav-icon {
          height: 26px;
          width: 26px;
        }

        .nav-title {
          margin-top: 2px;
          font-size: 12px;
          color: #C0C4CC;
        }

        .nav-active {
          color: $theme-color;
        }
      }
    }
  }
</style>
